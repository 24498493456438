import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=d81ee0a0&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"
import style0 from "./Home.vue?vue&type=style&index=0&id=d81ee0a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d81ee0a0",
  null
  
)

export default component.exports
import {QCard,QCardSection,QSeparator,QItem,QItemSection,QItemLabel,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QSeparator,QItem,QItemSection,QItemLabel,QIcon})
