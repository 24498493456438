
export default {
  data() {
    return {
      leftDrawerOpen: true,
      username: ""
    };
  },

  methods: {
    logout : function(){
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        this.$router.push('/login');
    },
  },

  mounted () {
    var get_profile = JSON.parse(localStorage.profile);
      
      this.username = get_profile.username;
      
  },
  
};
