


export default {
  data() {
    return {
     
      publicPath: process.env.BASE_URL,
      alert: false,
      simpan1: false,
      url : {
        GET_URL : this.$store.state.url.URL_APP_KECAMATAN + "api/v1/web_home/",
        PETA_DASAR: this.$store.state.url.URL_APP_KECAMATAN + "api/v1/petadasarnew/"

      },
      petadasar: [],
      users : null,
      peta_final : null,

      markersnya:[],
      infoWindowPos: '', // Infowindow position

      list_data : [],
      jumlah_zona : null,
      jumlah_titik : null,
      no: 0,

      form : {
            kecamatan: '',
          },
      kecamatan:[],


      clusterOptions: {
            gridSize: 30,
            maxZoom: 15,
        },
              
        ///////////////////////TITIK/////////////////////////////////
        polygonZonaOptions: {
          fillColor: 'red',
          fillOpacity: 0.1,
          strokeOpacity: 1,
          strokeWeight: 1,
          // Opsi warna dan kecerahan arsiran polygon
        },

        polygons_titik: [],
            
            polygonTitikOptions: {
              fillColor: 'blue',
              fillOpacity: 0.5,
              // Opsi warna dan kecerahan arsiran polygon
            },

            markers_titik: [] ,
          

          markerIcon: {
              url: 'img/firetruck.png',
              scaledSize: { width: 40, height: 40 },
            },

          
            ///////////////////////////END TITIK///////////////////////////// 



            center: { lat: 0, lng: 0 },
            devices: {},




            total_kasus : 0,
              daftar_tunggu : 0,
              total_berhasil : 0,
              total_user : 0,


              previousPositions: {}, // Store the previous positions of the markers
      
    }
  },
  methods: {

    Notify_marker : function(message){
          this.$q.notify({
            progress: true,
            message: message,
            color: 'success',
            avatar: 'https://cdn.quasar.dev/img/boy-avatar.png',
            position : 'top',
            actions: [
              { label: 'Keluar', color: 'yellow', handler: () => { /* ... */ } }
            ],
            timeout: 10000,
          })
        },

    handlePolygonClick: function(data){

      var hasil = (data.status_kasus == 3)?'Selesai':(data.status_kasus == 0?'Lapor':(data.status_kasus == 2)?'Batal':'Proses')
      this.Notify_marker(`Kasus ini adalah ${data.judul_kasus}, Status adalah ${hasil} `);

      },

    handleSatgas: function(data, id){
      
      this.Notify_marker(`Satgas ini adalah ${id} , menuju kasus (${data.judul_kasus})`);

      },

    getKecamatan: function() {

                fetch(this.url.PETA_DASAR + "kecamatan_all", {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    authorization: "xxx " + localStorage.token
                  },

                })
                  .then(res => res.json())
                  .then(res_data => {
                      
                    var tampung=[];

                    var kode = '';

                      for(var i=0;i<res_data.length;i++){

                        if(res_data[i].hasil.kode<10){
                            kode = `0${res_data[i].hasil.kode}`;
                        }
                        else{
                            kode = `${res_data[i].hasil.kode}`
                        }


                        tampung.push({
                          // kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.0${res_data[i].hasil.kode}`, 
                          kecamatan_id: `${res_data[i].hasil.no_prop}.0${res_data[i].hasil.no_kab}.${kode}`, 
                          nama_kecamatan: res_data[i].hasil.uraian
                        })
                      }

                    
                      this.kecamatan = tampung;
                  });
            },

    getPetadasar: function() {

            console.log();
            fetch(this.url.GET_URL + "petadasar", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    authorization: "xxx " + localStorage.token
                },
                body: JSON.stringify({
                    kecamatan_id : this.form.kecamatan,
                  })
                })
                .then(res => res.json())
                .then(res_data => {

                    this.petadasar = res_data;
                    
                    
                // 


                });
            },

    getView : function(){

          fetch(this.$store.state.url.URL_Kasus + "marker", {
              method: "GET",
              headers: {
              "content-type": "application/json",
              authorization: "xxx " + localStorage.token
              },
            
          })
              .then(res => res.json())
              .then(res_data => {
                  this.list_data = res_data.data;

                  var markers = [];

                  
                  for(var i = 0; i<this.list_data.length; i++){
                      markers.push({lat: this.list_data[i].latitude, lng:this.list_data[i].longitude})
                  }

                  this.markersnya = markers

                  // console.log(this.markersnya);

              });
        },

    getKasus : function(){

      fetch(this.$store.state.url.URL_Kasus + "getKasus", {
          method: "GET",
          headers: {
          "content-type": "application/json",
          authorization: "xxx " + localStorage.token
          },
        
      })
          .then(res => res.json())
          .then(res_data => {
              // console.log(res_data.data);
              this.total_kasus = res_data.data[0].total_kasus;
              this.daftar_tunggu = res_data.data[0].daftar_tunggu;
              this.total_berhasil = res_data.data[0].total_berhasil;
              this.total_user = res_data.data[0].total_user;

              

          });
      },


        cari_data: function() {
    
          this.getPetadasar();
          
        },




        // ----======================================
        // BACKUP GUYSSSSSS
    // async fetchLocations() {
    //   try {
    //     const response = await fetch('https://server.firetap.konaweselatankab.go.id/api/v1/updateLocation/locations'); // Ganti dengan URL server Anda
    //     const data = await response.json();
    //     this.devices = data;
    //     this.updateCenter();

    //     // console.log(this.devices);


    //   } catch (error) {
    //     console.error('Error fetching locations', error);
    //   }
    // },
    // updateCenter() {
    //   const deviceIds = Object.keys(this.devices);
    //   if (deviceIds.length) {
    //     const avgLat = deviceIds.reduce((sum, id) => sum + this.devices[id].lat, 0) / deviceIds.length;
    //     const avgLng = deviceIds.reduce((sum, id) => sum + this.devices[id].lng, 0) / deviceIds.length;

    //     this.center = { lat: avgLat, lng: avgLng };
    //     // console.log(this.center);
    //   }
    // }
    // ====================================
        // ----======================================
        // BACKUP GUYSSSSSS
    async fetchLocations() {
      try {
        const response = await fetch('https://server.firetap.konaweselatankab.go.id/api/v1/updateLocation/locations'); // Ganti dengan URL server Anda
        const data = await response.json();
        // this.devices = data;
        // this.updateCenter();

       console.log('Fetched data:', data);

        // Handle markers that are no longer in the fetched data
        Object.keys(this.devices).forEach((account) => {
          if (!data.hasOwnProperty(account)) {
            // If an account is no longer present in the fetched data, remove the marker
            this.$delete(this.devices, account);
            this.$delete(this.previousPositions, account);
          }
        });

        
       Object.keys(data).forEach((account) => {
          const location = data[account];
          const previousPosition = this.previousPositions[account];
          const newPosition = {
            lat: location.lat,
            lng: location.lng,
            judul_kasus: location.judul_kasus,
          };

          if (previousPosition) {
            this.animateMarkerMovement(previousPosition, newPosition, account);
          } else {
            // If no previous position exists, set the initial position directly
            this.$set(this.devices, account, newPosition);
          }

          // Update the previous position for the next fetch
          this.$set(this.previousPositions, account, newPosition);
        });

      } catch (error) {
        console.error('Error fetching locations', error);
      }
    },

    animateMarkerMovement(startPos, endPos, account) {
      const duration = 1000; // Animation duration in milliseconds
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const t = Math.min(elapsedTime / duration, 1);

        // Calculate interpolated position
        const lat = startPos.lat + (endPos.lat - startPos.lat) * t;
        const lng = startPos.lng + (endPos.lng - startPos.lng) * t;

        this.$set(this.devices, account, { lat, lng, judul_kasus: endPos.judul_kasus });

        // console.log(this.devices);

        if (t < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    }
    // ====================================
   

  },

  mounted () {
    this.getView();
    this.getKecamatan();
    this.getPetadasar();

    this.getKasus();

  },

  created() {
    this.fetchLocations();
    setInterval(this.fetchLocations, 2000); // Fetch data every 2 seconds
  }
}
