import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);


function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}


  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },

  {
    path: '/berita',
    name: 'berita',
    component: () => import('../views/berita.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/pengumuman',
    name: 'pengumuman',
    component: () => import('../views/pengumuman.vue'),
    beforeEnter: isLoggedIn,
  },


  {
    path: '/kasus',
    name: 'kasus',
    component: () => import('../views/kasus.vue'),
    beforeEnter: isLoggedIn,
  },
  
 
  {
    path: '/telepon',
    name: 'telepon',
    component: () => import('../views/telepon.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/peralatan',
    name: 'peralatan',
    component: () => import('../views/peralatan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/pegawai',
    name: 'pegawai',
    component: () => import('../views/pegawai.vue'),
    beforeEnter: isLoggedIn,
  },


  {
    path: '/pengguna',
    name: 'pengguna',
    component: () => import('../views/pengguna.vue'),
    beforeEnter: isLoggedIn,
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
