import Vue from 'vue'
import Vuex from 'vuex'

import { Loading,  QSpinnerFacebook,  } from 'quasar'
import { Notify } from 'quasar'


var URL = 'https://server.firetap.konaweselatankab.go.id/';

var URL_KECAMATAN = 'https://server-simbada.konaweselatankab.go.id/';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coordinat : {
      lat:-4.034694, 
      lng: 122.484263
    },
    btn : {
      add : false,
      edit : false,
      remove : false,
    },
    url : {
      URL_APP : URL,
      URL_APP_KECAMATAN : URL_KECAMATAN,
      URL_Berita : URL + 'api/v1/dataBerita/',
      URL_Pengumuman : URL + 'api/v1/dataPengumuman/',
      URL_Kasus : URL + 'api/v1/server_kasus/',
      URL_Pengguna : URL + 'api/v1/dataPengguna/',
      URL_Telepon : URL + 'api/v1/dataTelepon/',
      URL_Peralatan : URL + 'api/v1/dataPeralatan/',
      URL_Pegawai : URL + 'api/v1/dataPegawai/',
      
      
      URL_LOKASI : URL + 'api/v1/updateLocation/',

    
    },





    unit_kerja : '',

    page_first: 1,
    page_last: 0,
    cari_value: "",
    cek_load_data : true,

    
  },
  mutations: {

    getStorage(state){
      var get_profile = JSON.parse(localStorage.profile);
      state.unit_kerja = get_profile.profile.unit_kerja; 
    },
    shoWLoading(){
      const spinner = typeof QSpinnerFacebook !== 'undefined'
        ? QSpinnerFacebook // Non-UMD, imported above
        : Quasar.components.QSpinnerFacebook // eslint-disable-line


      Loading.show({
        spinner,
        spinnerColor: 'yellow',
        spinnerSize: 140,
        backgroundColor: 'purple',
        // message: 'Loading... Tunggu beberapa saat, system sedang menyesuaikan akun anda..!',
        // messageColor: 'white'
      })
    },
    hideLoading(){
      Loading.hide()
    },
    shoWNotify(state, message, color, icon){
      Notify.create({
        message: message,
        color: color,
        position : 'top-right',
        icon:icon
      })
    },
  },
  actions: {
  },
  modules: {
  }
})
